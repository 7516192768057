import { Link } from "react-router-dom";


function FooterNav() {
  return (
    <div className="footer">
      <Link to="/" className="footer-btn play">
        <span className="icon">🕹</span>
        <span className="text">Играть</span>
      </Link>
      <Link to="/leaderboard" className="footer-btn friends">
        <span className="icon">🤝</span>
        <span className="text">Друзья</span>
      </Link>
      <Link to="/withdraw" className="footer-btn withdraw">
        <span className="icon">💵</span>
        <span className="text">Вывод</span>
      </Link>
    </div>
  );
}

export default FooterNav;
