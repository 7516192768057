import React, { useState } from "react";
import { Link } from "react-router-dom";
import './withdraw.css';
import { withdraw, checker } from '../api/api';
import FooterNav from './FooterNav';
import Topbar from "./TopBar";

const Withdraw = () => {
  const [balance, setBalance] = useState(1200); // Стартовий баланс
  const [withdrawLink, setWithdrawLink] = useState("");
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleWithdrawLinkChange = (e) => {
    setWithdrawLink(e.target.value);
  };

  const handleAmountClick = async (amount) => {
    try {
      const res = await checker(localStorage.getItem('userId'), amount);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setIsButtonEnabled(false);
      return;
    }

    setSelectedAmount(amount);
    setIsButtonEnabled(true);
  };

  const submitWithdraw = async () => {
    if (!withdrawLink.trim()) {
      alert("Пожалуйста, укажите ссылку");
      return;
    }

    // Симулюємо запит на сервер для обробки виводу
    console.log("Відправлено посилання для виводу:", withdrawLink);

    try {
      const res = await withdraw(localStorage.getItem('userId'), withdrawLink, selectedAmount);

      // Показуємо сповіщення про успішний запит
      setNotificationVisible(true);
      setTimeout(() => {
        setNotificationVisible(false);
      }, 2000);

      // Очищення помилки
      setErrorMessage("");

    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
        setIsButtonEnabled(false);
        return;
      }
    }

    // Показуємо кнопку "Заказать" тільки якщо немає помилки
    setIsButtonEnabled(true);
  };

  return (
    <div className="container">
      <Topbar />

      <div className="content">

        {/* <div className="header-v">
          <Link to="/" className="header-link">
            <h1 className="cursorPoint">Вывод средств</h1>
          </Link>
        </div> */}
        {/* <div className="balance">
          <p>Ваш баланс:</p>
          <p className="balance-amount">{localStorage.getItem('tapCounter')}</p>
        </div>
        <div className="withdraw-link">
          <p>Ссылка для вывода (Roblox):</p>
          <input
            type="text"
            placeholder="Укажите ссылку"
            value={withdrawLink}
            onChange={handleWithdrawLinkChange}
          />
        </div> */}
        <p className="text-p">Ваш баланс: <strong>{(+localStorage.getItem('tapCounter')).toFixed(3) || 0} ⭐️</strong></p>
        <p className="text-p">Сколько звезд вы хотите вывести?</p>

        <div class="withdrawal-buttons">
          <button class="withdrawal-button" onClick={() => handleAmountClick(200)}>200 ⭐️</button>
          <button class="withdrawal-button" onClick={() => handleAmountClick(400)}>400 ⭐️</button>
          <button class="withdrawal-button" onClick={() => handleAmountClick(600)}>600 ⭐️</button>
        </div>
        {isButtonEnabled && (
          <div class="withdrawal-form" id="withdrawal-form">
            <p>Вы выбрали вывод: <span></span>{selectedAmount} ⭐️</p>
            <input
              type="text"
              class="form-input"
              placeholder="Укажите ссылку"
              value={withdrawLink}
              onChange={handleWithdrawLinkChange}
            />
            <button class="submit-button" onClick={submitWithdraw}>Заказать</button>
            <p>Укажите ссылку на аккаунт, на который будут переведены звезды:
              ссылка вида t.me либо @</p>
          </div>
        )}

        {notificationVisible && (
          <p
            id="submit-notification"
            className={`submit-notification ${notificationVisible ? "visible" : ""}`}
          >
            Запрос успешно отправлен!
          </p>
        )}


        {errorMessage && (
          <p className={`error-notification ${errorMessage ? "visible" : ""}`}>
            {errorMessage}
          </p>
        )}
      </div>
      <FooterNav />
    </div >
  );
};

export default Withdraw;
