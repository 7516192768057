import React from 'react';
import './topbar.css'
const Topbar = () => {
  return (
    <div className="topbar">
      <div className="profile">
        <div
          className="avatar"
          style={{ backgroundImage: `url(${localStorage.getItem('userPhoto') || 'https://via.placeholder.com/50'})` }}
        ></div>
        <div className="name">{localStorage.getItem('username')}</div>
      </div>
    </div>
  );
};

export default Topbar;