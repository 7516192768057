import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TapGame from "./components/BreadTapApp";
import Leaderboard from "./components/LeaderBord";
import Withdraw from "./components/Withdraw";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<TapGame />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/withdraw" element={<Withdraw />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
