import React, { useState } from "react";

function Accordion({ faqData }) {
  // Стан для відстеження відкритої вкладки
  const [openIndex, setOpenIndex] = useState(null);

  // Функція для перемикання вкладки
  const toggleTab = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Якщо вкладка відкрита, закриваємо її, інакше відкриваємо нову
  };

  return (
    <div className="faq">
      {faqData.map((item, index) => (
        <div className="faq-item" key={index}>
          <div className="faq-question" onClick={() => toggleTab(index)}>
            {item.question}
          </div>
          <div
            className={`faq-answer ${openIndex === index ? "open" : ""}`}
            style={{ display: openIndex === index ? "block" : "none" }}
          >
            {item.answer}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accordion;
