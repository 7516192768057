import { useState, useRef, useEffect } from "react";
import Pie from '../../../components/progress_indicator.js'
import '../style/game_page.css'
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import LinkButton from "../../../components/link_button.js";
import dataRewards from '../../../const.js'
import { useNotification } from "../../../components/NotificationProvider.js";
import { getTelegramUser, updateTapCounter, confirmTask } from '../../../api/api.js'
import AppBar from '../../../components/app_bar.js'
import BottomNavBar from "../../../components/bottom_nav_bar.js";
import Background from "../../../components/background.js";

function GamePage() {
  const { addNotification } = useNotification();

  const [isAnimating, setIsAnimating] = useState(true);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [tasks, setTasks] = useState(localStorage.getItem('tasks') ? JSON.parse(localStorage.getItem('tasks')) : []);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const [score, setScore] = useState(+localStorage.getItem('tapCounter') || 0);
  const [energy, setEnergy] = useState(+localStorage.getItem('energy') || 100);
  const [completedTasks, setCompletedTasks] = useState(new Set());
  const scoreRef = useRef(0);
  const energyScore = useRef(0);
  const progressRef = useRef(0);

  const rewardMessages = dataRewards;
  const [selectedText, setSelectedText] = useState('');
  const [typeText, setTypeText] = useState('text1');


  const [timeRemaining, setTimeRemaining] = useState(0); // Ваш час у секундах


  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      localStorage.setItem('totalCounter', 200);

      if (window?.Telegram?.WebApp) {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        const userData = window.Telegram.WebApp;
        const user = userData.initDataUnsafe.user;
        setUser(user);
        const data = await getTelegramUser(user.id).catch((err) => addNotification('error', 'Что-то не так с сервером. Мы уже чиним. Админ на связи.'));
        localStorage.setItem('referral', data.user.referralLink);
        localStorage.setItem('tapCounter', data.user.tapCounter.toFixed(3));
        setScore(data.user.tapCounter.toFixed(3));
        localStorage.setItem('energy', data.user.energy);
        localStorage.setItem('userId', user.id);
        localStorage.setItem('userPhoto', user.photo_url);
        localStorage.setItem('username', user.first_name || user.username);

        localStorage.setItem('user', user.id);
        localStorage.setItem('referrals', data.referrals.toString());
        localStorage.setItem('activeReferrals', data.activeReferrals.toString());

        localStorage.setItem('totalCounter', data.user.totalCounter);
        localStorage.setItem('virtualFriend', data.user.virtualFriend || 0);

        energyScore.current = data.user.energy
        setScore(data.user.tapCounter);
        setEnergy(Math.round(data.user.energy))
        scoreRef.current = data.user.tapCounter;
        // if (data.user.energy <= 0) {
        //   time(data.user.updated)
        // }
      }

      return;
    } catch (error) {
      console.error("Помилка завантаження даних користувача:", error);
    }
  };

  const handleConfirm = async (taskId) => {
    await confirmTask(localStorage.getItem('userId'), taskId).catch(err => addNotification('error', 'Что-то не так с сервером. Мы уже чиним. Админ на связи.'));

    let newTasks = tasks.filter(task => task.signature !== taskId) || [];
    localStorage.setItem('tasks', JSON.stringify(newTasks) || []);

    setTasks(prevTasks => prevTasks.filter(task => task.signature !== taskId));
  };

  const handleTaskClick = (taskId) => {
    setCompletedTasks((prev) => new Set(prev.add(taskId)));
  };

  const openTasksModal = (newTasks) => {
    localStorage.setItem('tasks', JSON.stringify(newTasks));
    setTasks(newTasks);
    setIsTasksModalOpen(true);
    setIsBottomSheetOpen(true);

  };

  const time = async (time) => {
    const lastUpdateDate = new Date(time);
    const currentDate = new Date();
    const targetDate = new Date(lastUpdateDate.getTime() + (15 * 60000));
    const timeDifference = targetDate - currentDate;
    const minutesRemaining = Math.max(Math.floor(timeDifference / (1000)), 0); // Используем Math.max, чтобы избежать отрицательных значений

    setTimeRemaining(minutesRemaining)
    // setTimeout(() => { setIsTimerVisible(false) }, minutesRemaining * 1000)
    // setIsTimerVisible(true)
  }

  const updateUserTapCounter = async () => {
    if (user) {
      try {
        if (scoreRef <= 0) {
          time(user.updated)
          return;
        }
        return updateTapCounter(user.id, scoreRef.current).catch((err) => addNotification('error', 'Что-то не так с сервером. Мы уже чиним. Админ на связи.'));
      } catch (error) {
        console.error("Помилка при відправці даних про тапи:", error);
      }
    }
  };

  const handleTap = (event) => {
    if (tasks.length > 0) {
      openTasksModal(tasks);
      return
    }

    if (!isAnimating) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 1000);
    }

    if (energyScore.current > 0) {
      energyScore.current -= 1
      const newEnergy = energyScore.current
      setEnergy(Math.round(newEnergy))
    } else {
      addNotification('success', 'Подождите, пока энергия восстановится.')
      return
    }

    const animation = document.createElement("div");
    animation.classList.add("animation");
    animation.textContent = "+0.285 ⭐️";

    const rect = event.target.getBoundingClientRect();
    animation.style.left = `${event.clientX - rect.left - 10}px`;
    animation.style.top = `${event.clientY - rect.top - 10}px`;
    animation.style.pointerEvents = "none";
    animation.style.position = "absolute";
    animation.style.zIndex = "10"; // Піднімаємо над іншими елементами
    // Додавання анімації до DOM

    const starContainer = event.target.closest(".tap-button");
    starContainer.appendChild(animation);

    // Видалення анімації після завершення
    setTimeout(() => {
      animation.remove();
    }, 1000);

    scoreRef.current += 0.285;
    scoreRef.current = Math.round(scoreRef.current * 1000) / 1000;
    const newScore = scoreRef.current;
    setScore(newScore);
    // alert((newScore % 50) * 2)
    progressRef.current += 2

    localStorage.setItem('tapCounter', scoreRef.current);


    if (progressRef.current > 100) {

      triggerReward();
    }

    updateUserTapCounter().then(res => {

      if (res?.tasks?.length > 0) {
        openTasksModal(res.tasks);
      }
      if (res?.timer) {
        scoreRef.current -= 1
        energyScore.current = res.energy || 0
        setScore(scoreRef.current)
        setEnergy(Math.round(energyScore.current))

        return time(res.timer)
      }
    })
  };

  const triggerReward = () => {
    progressRef.current = 0

    const randomMessage = rewardMessages[Math.floor(Math.random() * (rewardMessages.length - 1))];
    const randomIndex = Math.floor(Math.random() * 4);
    setTypeText(`text${randomIndex + 1}`)
    setSelectedText(randomMessage);
    setIsAnimating(true)
    setTimeout(() => {
      setSelectedText("")
      setIsAnimating(false)
    }, 2000);

    //  createFirework();
  };
  const createFirework = () => {
    const firework = document.createElement("div");
    firework.className = "firework";
    firework.style.left = `${Math.random() * 90}%`;
    firework.style.top = `${Math.random() * 50}%`;
    document.getElementById("fireworks-container").appendChild(firework);

    setTimeout(() => {
      firework.remove();
    }, 1000);
  };

  let middleContent;

  if (isTimerVisible) {
    middleContent = <div className="tap-content">
      <div className="timer-counter">
        <h1>04:23</h1>
        <h4>Энергия восстанавливается</h4>
      </div>
    </div>
  } else {
    middleContent = <div className="tap-button-container">
      <button class={`tap-button ${isAnimating ? "active" : ""}`} onClick={handleTap}>
        <img src="/assets/icons/ic_star.svg" alt="ic_star" />
      </button>
      <Pie percentage={progressRef.current} colour={'#00FF69'} />
      <span className={`circle-text ${typeText} ${isAnimating ? "active" : ""}`}>{selectedText}</span>
      {/* <span className={`circle-text text1 ${isAnimating ? "active" : ""}`}>Восторг!</span>
      <span className={`circle-text text2 ${isAnimating ? "active" : ""}`}>Продолжай!</span>
      <span className={`circle-text text3 ${isAnimating ? "active" : ""}`}>Молодец!</span>
      <span className={`circle-text text4 ${isAnimating ? "active" : ""}`}>Отлично!</span> */}
    </div>
  }

  return <>
    <AppBar />
    <BottomNavBar />
    <Background />
    <div className="game-page default-page-setting">
      <BottomSheet className="bottom-sheet" open={isBottomSheetOpen} onDismiss={() => setIsBottomSheetOpen(false)}>
        <div className="handle-icon-container">
          <img className="handle-icon" src="/assets/icons/ic_arrow_right.svg" alt="handle-icon" />
        </div>
        <div className="sheet-header">
          <h2>Нужно выполнить задания</h2>
        </div>
        <div className="task-list">
          {tasks.map((task, index) => (
            <LinkButton
              key={index}
              task={task}
              onTaskRemove={handleConfirm}
            />
          ))}
        </div>
      </BottomSheet>

      <div className="stars-counter">
        <h1>{score === 0 ? 0 : score.toFixed(3)}</h1>
        <h5>Stars</h5>
      </div>

      {middleContent}

      <div className="energy-row">
        <h4>Энергия</h4>
        <div className="energy-counter">
          <img src="/assets/icons/ic_energy.svg" className="navbar-icon" alt="ic_energy" />
          <h4>{energy.toString() || "Загрузка..."}</h4>
        </div>
      </div>
    </div>;
  </>
}

export default GamePage;