export default [
  "Молодец!",
  "Отлично!",
  "Здорово!",
  "Прекрасно!",
  "Так держать!",
  "Ты супер!",
  "Великолепно!",
  "Просто гений!",
  "Умница!",
  "Восхитительно!",
  "Браво!",
  "Невероятно!",
  "Круто!",
  "Легенда!",
  "Герой дня!",
  "Победитель!",
  "Удивительно!",
  "Идеально!",
  "Фантастика!",
  "Ты лучший!",
  "Потрясающе!",
  "Гений кликов!",
  "Непобедим!",
  "Эпично!",
  "Вау, ты сделал это!",
  "Ты машина!",
  "Лидер по жизни!",
  "Продолжай в том же духе!",
  "Король игры!",
  "Гуру кликов!",
  "Блестяще!",
  "Ты прирожденный победитель!",
  "Ух ты, невероятно!",
  "Уровень бог!",
  "Легенда хлеба!",
  "Словно волшебник!",
  "Да ты просто монстр!",
  "Властелин тапов!",
  "Непревзойденный!",
  "Топчик!",
  "Ты чемпион!",
  "Просто космос!",
  "Клик-мастер!",
  "Все аплодируют!",
  "Нереально круто!",
  "Ты идешь к вершине!",
  "Вселенная гордится тобой!",
  "Идеальный результат!",
  "Хлебушек одобряет!",
  "Золотые руки!",
  "Просто феномен!",
  "Мировой рекорд!",
  "Легенда кликеров!",
  "Бесподобно!",
  "Ты вдохновение для всех!",
  "Абсолютный победитель!",
  "Магия кликов!",
  "Энергия бесконечности!",
  "Настоящий профи!",
  "Все супер!",
  "Ты невероятен!",
  "Это успех!",
  "Ты неподражаем!",
  "Просто блеск!",
  "Мастерство на высоте!",
  "Ты лучший пример!",
  "Энергия рекордов!",
  "Это феноменально!",
  "Ты покоряешь вершины!",
  "Победа за тобой!",
  "Ты выше всех ожиданий!",
  "Новая планка установлена!",
  "Восторг гарантирован!",
  "Ты стремителен, как ветер!",
  "На шаг ближе к мечте!",
  "Превзошёл сам себя!",
  "Такого никто не видел!",
  "Ты всегда на высоте!",
  "Каждая секунда на вес золота!",
  "Продолжай сиять!",
  "Твой результат впечатляет!",
  "Так держать!",
  "Просто феерично!",
  "Ты покоряешь этот мир!",
  "Границы не для тебя!",
  "Каждый шаг — шедевр!",
  "Твои усилия окупаются!",
  "Ты пример для подражания!",
  "Ты создаёшь историю!",
  "Эпический результат!",
  "Твой успех неизбежен!",
  "Уровень профессионала!",
  "Никто не сравнится с тобой!",
  "Великолепный прорыв!",
  "Это только начало!",
  "Вперед к новым вершинам!",
  "Ты управляешь игрой!",
  "Прогресс неизбежен!",
  "Легенда настоящего времени!",
  "Невозможно оторваться!",
]