import axios from 'axios'
const API = process.env.REACT_APP_API_URL

export const getTelegramUser = async (userId) => {
  const response = await axios.get(`${API}/get-user-info/${userId}`)
  return response.data;
};

export const updateTapCounter = async (userId, tapCounter) => {
  const response = await axios.post(`${API}/taps`, {
    userId, tapCounter
  });
  return response.data

}

export const getLeaderBoard = async () => {
  const response = await axios.get(`${API}/get-top-users`)
  return response.data
}

export const checker = async (userId, count) => {
  const response = await axios.post(`${API}/check-ref`, {
    userId, count
  })
  return response
}

export const withdraw = async (userId, count, link = null) => {
  const response = await axios.post(`${API}/set-link`, {
    userId, link, count
  })
  return response
}

export const confirmTask = async (userId, signature) => {
  const response = await axios.post(`${API}/confirm-task`, {
    userId, signature
  })
  return response
}
