// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.referral-container {
  background: #2e2e2e;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.referral-text-input {
  width: 100%; padding: 10px; text-align: center; border-radius: 8px; border: none; 

}

.referral-text {
  font-size: 16px;
  color: #ffffff;
}

.additional-message {
  font-size: 14px;
  color: #ffffff;
  margin-top: 10px;
}

.copy-button {
  background: #4caf50;
  border: none;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background: #45a049;
}

.star-bonus {
  color: #ffd700;
  font-size: 16px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/menu.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,yCAAyC;EACzC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW,EAAE,aAAa,EAAE,kBAAkB,EAAE,kBAAkB,EAAE,YAAY;;AAElF;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".message {\n  margin-bottom: 20px;\n  font-size: 18px;\n  font-weight: bold;\n  color: #ffffff;\n}\n\n.referral-container {\n  background: #2e2e2e;\n  border-radius: 12px;\n  padding: 15px;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n}\n\n.referral-text-input {\n  width: 100%; padding: 10px; text-align: center; border-radius: 8px; border: none; \n\n}\n\n.referral-text {\n  font-size: 16px;\n  color: #ffffff;\n}\n\n.additional-message {\n  font-size: 14px;\n  color: #ffffff;\n  margin-top: 10px;\n}\n\n.copy-button {\n  background: #4caf50;\n  border: none;\n  color: #ffffff;\n  font-size: 16px;\n  padding: 10px 20px;\n  border-radius: 8px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.copy-button:hover {\n  background: #45a049;\n}\n\n.star-bonus {\n  color: #ffd700;\n  font-size: 16px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
