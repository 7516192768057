// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: #fff;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
} */

.topbar {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 800px;
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 10px;
  margin-left: 20px;
  color: #fff;
}

.profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url('https://via.placeholder.com/50');
  background-size: cover;
  background-position: center;
  border: 2px solid #ffd700;
}

.name {
  font-size: 18px;
  font-weight: bold;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/topbar.css"],"names":[],"mappings":"AAAA;;;;;;;;;;GAUG;;AAEH;EACE,aAAa;EACb,mBAAmB;EACnB,oCAAoC;EACpC,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;EACxC,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uDAAuD;EACvD,sBAAsB;EACtB,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["/* body {\n  font-family: Arial, sans-serif;\n  background: linear-gradient(135deg, #6a11cb, #2575fc);\n  color: #fff;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  height: 100vh;\n} */\n\n.topbar {\n  display: flex;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  width: 90%;\n  max-width: 800px;\n  padding: 10px 20px;\n  border-radius: 12px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n  margin: 10px;\n  margin-left: 20px;\n  color: #fff;\n}\n\n.profile {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n}\n\n.avatar {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  background-image: url('https://via.placeholder.com/50');\n  background-size: cover;\n  background-position: center;\n  border: 2px solid #ffd700;\n}\n\n.name {\n  font-size: 18px;\n  font-weight: bold;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
