import React, { useState, useEffect } from "react";
import "./timer.css"; // Можна підключити свій CSS для стилів

const Timer = ({ initialTime = 0 }) => {
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [isEnergyEmpty, setIsEnergyEmpty] = useState(false);

  // Эффект для запуска таймера при монтировании компонента
  useEffect(() => {
    if (timeRemaining <= 0) {
      setIsEnergyEmpty(true);
      return;
    }

    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          setIsEnergyEmpty(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []); // Пустой массив зависимостей, чтобы эффект выполнялся только один раз при монтировании

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="timer-container">
      <h1 className="timer-title">Таймер</h1>
      <p id="timer-display" className="timer-display">
        {formatTime(timeRemaining)}
      </p>
      {isEnergyEmpty && (
        <p id="energy-message" className="energy-message">
          Кончилась энергия
        </p>
      )}
    </div>
  );
};

export default Timer;
