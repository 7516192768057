// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.timer-container {
    background-color: #6EC346;
    padding: 20px 40px;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.timer-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.timer-display {
    font-size: 48px;
    font-weight: bold;
    color: white;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.energy-message {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
}

`, "",{"version":3,"sources":["webpack://./src/components/timer.css"],"names":[],"mappings":";;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,mDAAmD;AACvD;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,+BAA+B;AACnC","sourcesContent":["\n\n.timer-container {\n    background-color: #6EC346;\n    padding: 20px 40px;\n    border-radius: 20px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);\n}\n\n.timer-title {\n    font-size: 24px;\n    margin-bottom: 10px;\n    color: white;\n    text-transform: uppercase;\n    font-weight: bold;\n}\n\n.timer-display {\n    font-size: 48px;\n    font-weight: bold;\n    color: white;\n}\n\n.hidden {\n    visibility: hidden;\n    opacity: 0;\n    transition: visibility 0s 0.5s, opacity 0.5s linear;\n}\n\n.energy-message {\n    visibility: visible;\n    opacity: 1;\n    transition: opacity 0.5s linear;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
